.dropdownSwappables {
    width: 100%;
    height: 100px;
    position: absolute;
    transform: translate(-14px, 120px);
    z-index: 100000;
    height: fit-content;
    min-height: 204px;
    max-height: 500px;
    flex-shrink: 0;


    border-radius: 6px;
    background: #31475E;

    padding: 10px;
    transform-origin: 0 0;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.searchPair{
    width: 100%;
}
.search_area {
    position: relative;
    width: 100%;
    border-radius: 13px;
    background: #3A5876;
    color: #0C3967;
    font-family: Inter;
    padding: 5px 10px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    box-sizing: border-box;


    display: flex;
    gap: 5px;

}.search_area>input{
    width: 90%;
    box-sizing: border-box;
    background-color: transparent;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    outline: none;

}.search_area>input::placeholder{
    color: #0C3967;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.allPairs{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    margin-top: 10px;
}
.pairStyle{
    width: 100%;
    border-radius: 2px;
background: rgba(16, 95, 134, 0.453);
padding: 8px 10px;
box-sizing: border-box;

display: flex;
flex-wrap: nowrap;
justify-content: flex-start;
align-items: center;
gap: 10px;
}
.pairStyle > img{
    width: 16px;
    height: 16px ;
    border-radius: 16px;

}
.pairStyle > p{
    color: #FFF;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}