@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

* {
    margin: 0px;
    padding: 0px;
}

body {
    background: #1C2936;
}

.form-container {
    /* border: 1px solid white; */
    width: 100vw;
    max-width: 480px;
    height: fit-content;
    min-height: 267px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-end;

}

.form-container .div {
    /* border: 1px solid white; */
}

.form-container>form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: fit-content;
    align-items: center;
}

.form-container>form>.input-area {
    width: 90%;
    height: 96px;
    flex-shrink: 0;
    border-radius: 11px;
    background: #121E2B;
    padding: 14px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}


.form-container>form>.input-area .box1 {
    display: flex;
    flex-direction: column;
    gap: 14px;
    justify-content: flex-start;
    max-width: 35%;
}

.form-container>form>.input-area .box1 .t1 {
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-self: flex-start;
    width: 100%;
    text-wrap:none;
}

.form-container>form>.input-area .box1 .token-contract {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 5px;
    padding: 12px;

    border-radius: 8px;
    background: #10161D;
    /* max-width: 35%; */
    cursor: pointer;
}
.form-container>form>.input-area .box1 .token-contract img{
    width: 40px;
    height: auto;
    border: none;
    border-radius: 40px;
    background-color: transparent;
}

.form-container>form>.input-area .box1 .t2 {
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    white-space: pre;
}





.form-container>form>.input-area .box2 {
    height: 80%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}










.form-container>form>.input-area .box2 {
    max-width: 50%;
    /* border: 1px solid white; */
    padding-right: 20px;
}

.form-container>form>.input-area .box2 .t2 {
    width: fit-content;
    align-self: flex-end;

    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.form-container>form>.input-area .box2 input {
    border: none;
    outline: none;
    background-color: transparent;
    color: #7187a8;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: right;
    width: 100%;
    direction: none;
}


::placeholder {
    color: #475974;
    opacity: 1;
    /* Firefox */
}

::-ms-input-placeholder {
    /* Edge 12-18 */
    color: red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}




.swap-button {
    width: 442px;
    height: 51px;
    flex-shrink: 0;
    border-radius: 9px;
    border: 1px solid #85CA5B;
    background: rgba(89, 120, 15, 0.37);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}